import { useSearchParams } from 'next/navigation'
import { useMainContext } from '@/core/contexts/main'
import { useSocketContext } from '@/core/contexts/socket'
import { usePathname } from 'next/navigation'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useClientSide } from '@/core/hooks/common/setting'
import {
    useGetBankSettings,
    useGetCoinSettings,
} from '@/core/services/react-query/otc'
import { deformatNumber, formatNumber } from '@/core/utils/common'
import { useAuthContext } from '@/core/contexts/auth'

export const useOtc = () => {
    const params = useSearchParams()
    let pathname = usePathname()
    if(pathname.startsWith("fa/") || pathname.startsWith("en/"))
        pathname = pathname.substring(4);
    else
        pathname = pathname.substring(1);

    console.log('pathname => ' + pathname);

    const preCoin = params.get('coin') || pathname;
    console.log('preCoin => ' + preCoin);
    const { token } = useMainContext()

    const { pricesList: _prices } = useSocketContext()

    const [pricesList, setPricesList] = useState([])
    useEffect(() => {
        setPricesList(_prices)
    }, [_prices])

    const clientServed = useClientSide()

    const { profile } = useAuthContext()
    const { data: coinSetting } = useGetCoinSettings()
    const { data: bankSetting } = useGetBankSettings()

    const [coin, setCoin] = useState('')
    const [volume, setVolume] = useState('1')
    const [price, setPrice] = useState('')
    const [type, setType] = useState('buy')


    /* updating price when socket refresh */
    useEffect(() => {
        console.log('useEffect is call');
        console.log(pricesList);
        console.log(coin);
        if (!!coin) {
            const newPrice = pricesList.find((x) => x?.id === coin.id)
            console.log('newPrice ')
            console.log(newPrice)
            if (!!newPrice)
                setPrice(
                    formatNumber(newPrice[type] * deformatNumber(volume), {
                        type: type === 'buy' ? 'irt' : coin.id,
                    })
                )
        }
        console.log(coin);  
    }, [pricesList])

    // types: close - login - min - max - otc - balance
    const [modal, setModal] = useState('close')
    const [limits, setLimits] = useState()

    // setting coin from params
    useEffect(() => {
        if (preCoin && pricesList?.length && !coin) {
            console.log('cont if');
            const exist = pricesList.find((item) => item.id === preCoin)
            console.log('exist is => ' + exist)
            if (!!exist) setCoin(exist)
        }
    }, [pricesList])

    const initialCoinLoaded = useRef(false)
    useEffect(() => {
        if (pricesList?.length && !initialCoinLoaded.current && !preCoin) {
            initialCoinLoaded.current = true
            setCoin(pricesList[0])
        }
    }, [pricesList])

    useEffect(() => {
        console.log(coin);
        if (!!coin) {
            const _price = deformatNumber(volume) * coin[type]
            setPrice(formatNumber(_price, { type: 'irt' }))
        }
    }, [coin, type])

    const onPriceChange = (v) => {
        const _volume = deformatNumber(v) / coin[type]
        setVolume(formatNumber(_volume, { type: coin.id }))
        setPrice(v)
    }

    const onVolumeChange = (v) => {
        const _price = deformatNumber(v) * coin[type]
        setVolume(v)
        setPrice(formatNumber(_price, { type: 'irt' }))
    }

    const onAction = (action) => {
        console.log('in acction');
        console.log(action);
        console.log(type);
        console.log('coin is -===== > ');
        console.log(coin);
        console.log('coinSetting');
        console.log(coinSetting);
        console.log('===============');

        let curr = null, min, max
        if (action === type) {
            if (!coin || !coinSetting || !bankSetting) return
            if (!token || !profile) {
                setModal('login')
                return
            }

            let balanceError
            if (type === 'buy')
                balanceError = profile.data.balance < deformatNumber(price)
            else {
                const currWallet = profile.data.coins.find(
                    (item) => item.coin === coin.id
                )
                if (currWallet)
                    balanceError = currWallet.amount < deformatNumber(volume)
                else balanceError = true
            }
            if (balanceError) {
                setModal('balance')
                return
            }

            if (type === 'buy')
            {
                console.log('item is');
                console.log('item is2');
                curr = coinSetting.find((item) => item?.id === coin.id)
                console.log(curr);
                if (!curr) {
                    curr = bankSetting;
                }
                min = curr.otc.min
                max = curr.otc.max
    
            }
            else
            {
                curr = coinSetting.find((item) => item?.id === coin.id)
                if (!curr) {
                    curr = bankSetting;
                }
                min = curr.otc.min
                max = curr.otc.max
            }

            console.log('curr');
            console.log(curr);

            let maxError, minError
            if (type === 'buy') {
                maxError = max && deformatNumber(volume) > max
                minError =
                    (min && deformatNumber(volume) < min) ||
                    deformatNumber(volume) === 0 ||
                    !volume
            } else {
                maxError = max && deformatNumber(price) > max * coin.sell
                minError =
                    (min && deformatNumber(price) < min) ||
                    deformatNumber(price) === 0 ||
                    !volume
            }

            if (maxError) {
                setLimits(max)
                setModal('max')
                return
            }
            if (minError) {
                setLimits(min ? min : '0.000001')
                setModal('min')
                return
            }

            setModal('otc')
        } else setType(action)
    }

    const onClose = () => {
        setModal('close')
        setLimits(null)
    }

    const toomanCoin = {
        coin: 'irt',
        id: 'irt',
        name: 'toman',
    }

    const balance = useMemo(() => {
        let b = '0'
        if (!!profile) {
            if (type === 'buy') b = `${formatNumber(profile.data.balance)} IRT`
            else
                b = `${
                    formatNumber(
                        profile.data.coins.find((i) => i.coin === coin.id)
                            ?.amount
                    ) || 0
                } ${coin.id?.toUpperCase()}`
        }
        return b
    }, [profile, type, coin])

    const onBalanceClick = () => {
        try {
            let value = balance.split(' ')
            const fn = type === 'buy' ? onPriceChange : onVolumeChange
            fn(value[0])
        } catch (err) {}
    }

    const coinLimits = useMemo(() => {
        const temp = coinSetting?.filter((item) => item?.id === coin?.id)
        let setting = {}
        if (temp?.length) setting = temp[0]?.otc
        return setting
    }, [coin, coinSetting])

    return {
        profile,
        balance,
        onBalanceClick,
        coin,
        price,
        onPriceChange,
        clientServed,
        volume,
        onVolumeChange,
        setCoin,
        onAction,
        modal,
        toomanCoin,
        onClose,
        limits,
        type,
        setType,
        coinLimits,
    }
}
