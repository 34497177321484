import {useMutation, useQuery} from "react-query";
import {postApi} from "@/core/services/fetch-api/post";
import {normalFetch} from "@/core/services/fetch-api/get";


export const useOtcCalculate = () => {

    return useMutation(
        'otc-calculate', (data) => postApi(data, 'settings/wages/calculate')
    )
}


export const useGetCoinSettings = () => {

    return useQuery(
        'coin-settings', () => normalFetch('settings/coins'),
        {
            select: res => res?.data?.data
        }
    )
}

export const useGetBankSettings = () => {

    return useQuery(
        'bank-settings', () => normalFetch('settings/banks'),
        {
            select: res => res?.data?.data
        }
    )
}


export const useCoinCategories = () => {

    return useQuery(
        'coin-categories', () => normalFetch('cryptoCategories/all'),
        {
            select: res => res?.data?.data
        }
    )
}


export const useMarketVolumes = () => {

    return useQuery(
        'market-volumes', () => normalFetch('market/volume'),
        {
            select: res => res?.data?.data?.otc,
            refetchInterval: 5 * 60 * 1000
        }
    )
}
