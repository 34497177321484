import { ClipLoader } from 'react-spinners'

export const Button = ({
	icon = '',
	iconType = 'duotone',
	variant = 'primary',
	type = 'button',
	style = 'default',
	className = '',
	loading = false,
	disabled = false,
	children,
	...rest
}) => {
	const hasIcon = !!icon

	return (
		<button
			disabled={disabled}
			type={type}
			className={`btn btn-${style} btn-${variant} ${className}`}
			{...rest}
		>
			{!!loading && <ClipLoader size={18} />}
			{!loading && (
				<>
					{children}
					{!!hasIcon && <i className={`fa-${iconType} fa-${icon} ms-2`} />}
				</>
			)}
		</button>
	)
}

//test push
