import axios from "axios";
import {BASE_URL} from "@/core/constants/urls";


export const tradeAPIs = {
    otc: {
        create(data) {
            return axios.post(BASE_URL + 'otc', data)
        },
    },
}

export const auditAPIs = {
    coining: {
        withdraw(data) {
            return axios.post(BASE_URL + 'coining/withdraw', data)
        },
    },
    banking: {
        withdraw(data) {
            return axios.post(BASE_URL + 'banking/withdraw', data)
        },
    },
}
