import {useEffect, useLayoutEffect, useRef, useState} from "react";


export const useRunAfterUpdate = () => {
    const ref = useRef(null)
    useLayoutEffect(() => {
        if (ref.current){
            ref.current()
            ref.current = null
        }
    })
    return fn => (ref.current = fn)
}


export const useClientSide = () => {
    const [mounted, setMounted] = useState(false)
    useEffect(() => {
        setMounted(true)
    }, [])
    return mounted
}
