import {
    CFlex,
    CloseIcon,
    CMargin,
    DText,
    Flex,
    IconWrapper,
    OtcErrorIconWrapper,
} from '@/styles/styled'
import { IoMdAlert } from 'react-icons/io'
import { getMainTheme } from '@/core/utils/theme'
import { useMainContext } from '@/core/contexts/main'
import { useRouter } from 'next/router'
import Text from '@/components/common/utils/Text'
import Link from 'next/link'
import { routes } from '@/core/constants/routes'
import { formatNumber } from '@/core/utils/common'
import { HOME } from '@/core/constants/urls'

// type: login - min - max - balance
const OtcErrorModal = ({ onClose, limit, coin, type, tradeType }) => {
    const { theme } = useMainContext()
    const { locale } = useRouter()

    const hrefs = {
        login: routes.login,
        balance: routes.wallets,
    }
    const path = hrefs[type]
    console.log('path: ', path)

    return (
        <CFlex fw>
            <Flex fw justify={'flex-end'}>
                <IconWrapper onClick={onClose}>
                    <CloseIcon size={24} />
                </IconWrapper>
            </Flex>
            <Flex fw>
                <OtcErrorIconWrapper>
                    <IoMdAlert
                        size={42}
                        color={getMainTheme(theme, locale).mainOrange}
                    />
                   
                </OtcErrorIconWrapper>
            </Flex>
            <CMargin margin={'12px'} />
            <Flex fw justify={'flex-start'}>
                <DText primary cFontSize={'1rem'}>
                    {(type === 'min' || type === 'max') && (
                        <Flex fw wrap>
                            <Text tid={`${type}-part-1`} />
                            <Text
                                tid={`${formatNumber(limit)} ${
                                    tradeType === 'buy'
                                        ? locale === 'fa'
                                            ? coin.fa
                                            : coin.name
                                        : locale === 'fa'
                                        ? 'تومان'
                                        : 'Tooman'
                                }`}
                                className={'mx-2'}
                            />
                            <Text tid={`${type}-part-2`} />
                            
                        </Flex>
                    )}
                    {(type === 'login' || type === 'balance') && (
                        <Text tid={`${type}-error`} />
                    )}
                </DText>
            </Flex>
            <CMargin margin={'14px'} />
            <Flex fw>
                {path ? (
                    <a
                        className={'btn btn-primary w-[160px] h-[40px]'}
                        href={HOME + path}>
                        <Text tid={`${type}-btn`} />
                    </a>
                ) : (
                    <div
                        className={
                            'btn btn-primary w-[160px] h-[40px] cursor-pointer'
                        }
                        onClick={onClose}>
                        <Text tid={`${type}-btn`} />
                    </div>
                )}
            </Flex>
        </CFlex>
    )
}

export default OtcErrorModal
