import { DText, Flex } from '@/styles/styled'
import { ICON_URL, SOCKET_URL } from '@/core/constants/urls'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { FiChevronDown } from 'react-icons/fi'

const CoinRow = ({ coin, ...rest }) => {
    return (
        <Flex
            fh
            fw
            {...rest}
            justify='space-between'
            className='gap-x-2 w-full'>
            {!!coin && (
                <>
                    <Image
                        src={ICON_URL + `assets/icon/${coin.id}.png`}
                        width={30}
                        height={30}
                        alt={coin.id}
                    />

                    <div
                        className={` md:text-sm lg:text-base me-auto`}>{`${coin?.id?.toUpperCase()}`}</div>

                    <FiChevronDown size={15} className='dark:text-white' />
                </>
            )}
        </Flex>
    )
}

export default CoinRow
