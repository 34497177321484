import { AnimatePresence, motion } from 'framer-motion'
import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import {useMainContext} from "@/core/contexts/main";
import useClickOutside from "@/core/hooks/common/useClickOutside";
import {fadeVariants} from "@/core/utils/theme";


const ModalLayout = ({ width, children, onClose, open}) => {

    const modalRef = useRef()
    useClickOutside(modalRef, onClose)
    const { theme } = useMainContext()

    return (
        <Body _theme={theme} open={open}>
            <AnimatePresence exitBeforeEnter>
                {open && (
                    <Wrapper
                        ref={modalRef}
                        variants={fadeVariants}
                        initial='out'
                        animate='in'
                        exit='out'
                        width={width}
                    >
                        {children}
                    </Wrapper>
                )}
            </AnimatePresence>
        </Body>
    )
}

const Body = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: ${props => props._theme === 'dark' ? '#00000080' : '#435a6fb3'};
	display: ${props => props.open ? 'flex' : 'none'};
	align-items: center;
	justify-content: center;
	z-index: 11111111111111;
    direction: ${props => props.theme.english ? 'ltr' : 'rtl'};
`

const Wrapper = styled(motion.div)`
	width: 100%;
	max-width: ${(props) => props.width};
	padding: 20px;
	background-color: ${(props) => props.theme.mainBg};
	border-radius: 8px;
	box-shadow: 0 0 4px ${(props) => props.theme.shadow};

	@media screen and (max-width: 480px) {
		padding: 30px 20px;
		width: 90%;
	}
`

export default ModalLayout
